<template>
  <div class="jobAdvertisement">
    <div class="filters">
      <div class="filters-container">
        <div class="search">
          <label for="search">Cerca</label>
          <input id="search" type="text" name="" placeholder="Nome, Utente CVing" @input="filterSearch($event)">
        </div>
      </div>
    </div>
    <div class="action-container">
      <div class="buttons">
        <div class="btn-badge">
          <material-button :small="true" :text="'Da Verificare'" type="info" :class="{ disabled: campaignsType != 'easy_post_to_approve' }" @click="getCompanies('easy_post_to_approve')" />

          <span class="counter-badge">
            {{ verified_easy_post_companies_to_approve }}
          </span>
        </div>

        <div class="btn-badge">
          <material-button :small="true" :text="'Tutte'" type="info" :class="{ disabled: campaignsType != 'all_easy_post' }" @click="getCompanies('all_easy_post')" />

          <span class="counter-badge">
            {{ total_easy_post_companies }}
          </span>
        </div>

        <div class="btn-badge">
          <material-button :small="true" :text="'Non Verificate'" type="info" :class="{ disabled: campaignsType != 'not_verified_easy_post' }" @click="getCompanies('not_verified_easy_post')" />

          <span class="counter-badge">
            {{ unverified_easy_post_companies }}
          </span>
        </div>
      </div>
      <div>
        <material-button
          v-if="permissions.canUse('EASY_POST_MODIFY') && campaignsType !== 'not_verified_easy_post'"
          text="Elimina annuncio"
          type="error"
          :small="true"
          :disabled="
            advertisementsSelected.length === 0 ||
              advertisementsSelected.length > 1
          "
          @click="openDeleteDialog()"
        />
        <material-button
          v-if="permissions.canUse('EASY_POST_MODIFY') && campaignsType !== 'not_verified_easy_post'"
          text="Non approvare annuncio"
          type="warning"
          :small="true"
          :disabled="
            advertisementsSelected.length === 0 ||
              advertisementsSelected.length > 1
          "
          @click="showWarning = true"
        />
        <material-button
          v-if="permissions.canUse('EASY_POST_MODIFY') && campaignsType !== 'not_verified_easy_post'"
          :small="true"
          :text="
            advertisementsSelected.length <= 1
              ? 'Approva annuncio'
              : 'Approva annunci'
          "
          type="info"
          :disabled="advertisementsSelected.length === 0"
          @click="openApproveDialog()"
        />
        <material-button
          v-if="permissions.canUse('EASY_POST_MODIFY') && campaignsType === 'not_verified_easy_post'"
          :small="true"
          text="Reinvia email"
          type="info"
          :disabled="!resendEmailEnabled()"
          @click="openResendEmailModal()"
        />
      </div>
    </div>
    <div class="table">
      <table cellspacing="0">
        <thead>
          <tr>
            <th class="text-center" style="width: 50px">
              <input
                type="checkbox"
                :checked="
                  advertisementsSelected.length == getFilteredRows().length
                "
                @click="selectAll()"
              >
            </th>
            <th v-for="header of headers.filter(f => { return !(campaignsType !== 'all_easy_post' && f === 'Approvato da') })" :key="header" class="orderable" @click="changeOrder(header)">
              <div class="">
                <span>{{ header }}</span>
                <font-awesome-icon
                  v-if="filters.order.field == header"
                  :icon="
                    header != 'Data Richiesta'
                      ? filters.order.asc
                        ? 'arrow-down-a-z'
                        : 'arrow-down-z-a'
                      : filters.order.asc
                        ? 'arrow-down-1-9'
                        : 'arrow-down-9-1'
                  "
                />
              </div>
            </th>
            <th style="width: 50px" />
          </tr>
        </thead>
        <tbody class="table-body">
          <slot v-for="(advertisement, index) in getFilteredRows()" :key="index">
            <tr @click="toggleExpand(advertisement.coid, advertisement.cid)">
              <td style="width: 50px">
                <input
                  :id="'collapsible' + index"
                  class="toggle"
                  type="checkbox"
                  :checked="
                    advertisementsSelected.indexOf(advertisement.cid) !== -1
                  "
                  @change="select(advertisement.coid, advertisement.cid)"
                  @click.stop=""
                >
              </td>
              <td>
                <span>{{ advertisement.co_name }}</span>
              </td>
              <td>
                <span> {{ advertisement.coid }}</span>
              </td>
              <td>
                <span class="dot" :class="'dot-color' + advertisement.easy_post_status" />
                <span v-if="advertisement.easy_post_status === 2">Approvato</span>
                <span v-if="advertisement.easy_post_status === 3">In Revisione</span>
                <span v-if="advertisement.easy_post_status === 4">Rifiutato</span>
                <span v-else-if="advertisement.easy_post_status === 1">Da approvare</span>
              </td>
              <td>
                {{ dayjs.utc(advertisement.campaign_date).local().format('DD-MM-YYYY HH:mm') }}
              </td>
              <td v-if="campaignsType === 'all_easy_post'">
                {{ advertisement.backofficeusername }} {{ advertisement.backofficeusersurname }}
              </td>
              <td>
                {{ advertisement.piva }}
              </td>
              <td>
                {{ advertisement.codice_fiscale }}
              </td>
              <td>
                <span v-if="advertisement.giuridic_form_type === 1">Società commerciale ITA</span>
                <span v-if="advertisement.giuridic_form_type === 2">Ditta individuale / libero professionista ITA</span>
                <span v-if="advertisement.giuridic_form_type === 3">Associazione non commerciale</span>
              </td>
              <td v-if="campaignsType === 'not_verified_easy_post' && true">
                <!-- {{ advertisement.mail_verify_account }} -->
                <p v-if="advertisement.mail_verify_account[0].email_state === 0">
                  Invio email in corso
                </p>
                <p v-if="advertisement.mail_verify_account[0].email_state === 1">
                  Invio email in corso
                </p>
                <p v-if="advertisement.mail_verify_account[0].email_state === 2">
                  Email Inviata , In attesa di ricezione
                </p>
                <p v-if="advertisement.mail_verify_account[0].email_state === 3">
                  Email ricevuta
                </p>
                <p v-if="advertisement.mail_verify_account[0].email_state === 4">
                  Invio Email fallito
                </p>
                <p>
                  <span v-if="advertisement.mail_verify_account[0].resent_date">
                    {{ dayjs.utc(advertisement.mail_verify_account[0].resent_date).local().format('DD-MM-YYYY HH:mm') }}
                  </span>
                  <span v-else-if="advertisement.mail_verify_account[0].update_date">
                    {{ dayjs.utc(advertisement.mail_verify_account[0].update_date).local().format('DD-MM-YYYY HH:mm') }}
                  </span>
                  <span v-else>
                    {{ dayjs.utc(advertisement.mail_verify_account[0].creation_date).local().format('DD-MM-YYYY HH:mm') }}
                  </span>
                </p>
              </td>
              <td>
                <material-button type="info" text="Visualizza annuncio" :small="true" />
              </td>
              <td
                class="chevron"
                :class="{
                  '--open': collapsiblePanelOpened === advertisement.coid,
                }"
              >
                <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                </svg>
              </td>
            </tr>
            <transition name="grow" type="animation">
              <tr v-if="collapsiblePanelOpened === advertisement.coid" class="collapsible-content">
                <td colspan="12">
                  <previewAdv ref="previewAdvRef" :preview="true" :selected="previewAdv" style="min-height: 440px" />
                </td>
              </tr>
            </transition>
          </slot>
        </tbody>
      </table>
    </div>

    <div v-show="showWarning" class="dialog">
      <div class="dialog-content">
        <div class="dialog-header">
          <div class="title" style="font-size:20px">
            Hai deciso di <b>non</b> approvare l'annuncio:
          </div>
          <div class="close" @click="showWarning = false">
            <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
            </svg>
          </div>
        </div>
        <p>
          indica i motivi per cui l'annuncio non rispetta i criteri di
          pubblicazione.
        </p>
        <div>
          <div class="avatar-title">
            <img class="announcement" src="../assets/announcement.png">
            <img class="newspaper" src="../assets/newspaper.png">
          </div>
        </div>
        <div class="warning-text">
          <p>L'utente riceverà una email e potrà correggere ciò che segnali.</p>
          <p>Puoi aggiungere ulteriori informazioni modificando il testo qui sotto.</p>
        </div>
        <div class="warning-wrapper">
          <div class="input-container">
            <v-select
              v-model="warning"
              return-object
              label="text"
              :options="getWarningsOpt"
              :clearable="false"
              :close-on-select="true"
            />
            <textarea v-model="warningText.value" :rows="warningText.value.length > 100 ? 2 : 1" />
            <material-button style="width:150px!important" text="+ criterio" :small="true" @click="addWarning" />
          </div>
        </div>
        <div v-if="previewListWarning.length > 0" class="preview-list">
          <i>Preview:</i>
          <ul>
            <li v-for="(w, index) in previewListWarning" :key="index + '_w'">
              <span> - {{ w.value }}</span>
              <span>
                <svg style="width:24px;height:24px" viewBox="0 0 24 24" @click="removeWarning(w)">
                  <path fill="currentColor" d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" />
                </svg>
              </span>
            </li>
          </ul>
        </div>
        <div class="warning-action">
          <material-button text="Non approvare" :disabled="previewListWarning.length === 0" @click="rejectAdvertisment()" />
        </div>
      </div>
    </div>

    <div v-show="showResendEmailModal" class="dialog">
      <div class="dialog-content" style="width: 400px;">
        <form
          class="flex flex-column gap-1 custom-inputs"
          autocomplete="off"
          @submit.prevent
          @keydown.enter.prevent=""
        >
          <strong style="margin-bottom: 1rem">Email di verifica annuncio</strong>

          <div class="flex">
            <div class="flex-1">
              <input
                v-model="newEmail"
                type="email"
                placeholder="Inserisci la nuova email"
                class="w-full"
                style="margin-bottom: 0.75rem; width: 300px"
                @keydown.enter.prevent
              >
            </div>
          </div>

          <div class="flex">
            <div class="flex-1 flex gap-1 flex-end">
              <material-button
                text="ANNULLA"
                type="light"
                @click="closeResendEmailModal()"
              />
              <material-button text="CREA" type="info" @click="resendEmail()" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <Dialog ref="approveDialog" title="Vuoi Approvare?" subtitle="se confermi, l'annuncio verrà approvato" button-text="Conferma" @buttonAction="approveAdvertisment" />

  <Dialog ref="deleteDialog" title="Vuoi Eliminare?" subtitle="se confermi, l'annuncio verrà eliminato" button-text="Conferma" @buttonAction="removeAdvertisment" />
</template>

<script>
import { toRefs, reactive, onMounted, inject, computed, watch, ref } from '@vue/runtime-core'
import MaterialButton from '../components/MaterialButton.vue'
import previewAdv from '../components/companiesList/campaigns/CampaignManagement.vue'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import { navbarStore } from '@/stores/navbar'
import { spinnerStore } from '@/stores/spinner'
import { sbapibackoffice, api } from '@/api'
import Dialog from '../components/Dialog.vue'
import { permissionsStore } from '@/stores/permissions'
import dayjs from 'dayjs'
require('dayjs/locale/it')
dayjs.locale('it')
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default {
  components: {
    MaterialButton,
    Dialog,
    previewAdv
  },
  setup() {
    const permissions = permissionsStore()
    const state = reactive({
      showPreview: false,
      warning: 'Titolo annuncio',
      warningOpt: [
        'Titolo annuncio',
        'Logo azienda',
        'Cover azienda',
        'Video-colloquio',
        'Contenuto dell\'annuncio'
      ],
      warningText: { text: 'Titolo annuncio', value: 'Titolo annuncio: modifica il titolo del tuo annuncio. Assicurati che contenga esclusivamente la denominazione della posizione lavorativa. Ti consigliamo inoltre di utilizzare parole chiave che descrivano accuratamente la posizione, di evitare gergo aziendale e di non utilizzare abbreviazioni.' },
      previewListWarning: [],
      showWarning: false,
      previewAdv: {},
      tag: [],
      tags: [],
      advertisement: [],
      filters: {
        filterType: [],
        order: {
          field: 'Data Richiesta',
          asc: false
        }
      },
      search: [],
      dateFrom: null,
      dateTo: null,
      headers: [
        'Azienda',
        'Coid',
        'Richiesta',
        'Data Richiesta',
        'Approvato da',
        'P.iva',
        'CF',
        'F. Giuridica',
        'Stato Verifica',
        '',
        ''
      ],
      advertisementsSelected: [],
      collapsiblePanelOpened: '',
      educationalLevels: [],
      dayjs,
      campaignsType: 'easy_post_to_approve',
      total_easy_post_companies: 0, // TUTTE
      verified_easy_post_companies_to_approve: 0, // DA VERIFICARE
      unverified_easy_post_companies: 0, // NON VERIFICATE
      showResendEmailModal: false,
      newEmail: ''
    })
    const toast = inject('$toast')
    const navbar = navbarStore()
    const spinner = spinnerStore()

    const approveDialog = ref(null)
    const deleteDialog = ref(null)
    const previewAdvRef = ref(null)

    onMounted(() => {
      navbar.title = 'Verifica Annunci'

      getCompanies()
      getCounts()
    })

    const getCounts = () => {
      spinner.show()
      sbapibackoffice
        .get('/easypost/campaign/recordscount')
        .then(({ data }) => {
          state.total_easy_post_companies = data.total_easy_post_companies
          state.verified_easy_post_companies_to_approve = data.verified_easy_post_companies_to_approve
          state.unverified_easy_post_companies = data.unverified_easy_post_companies
        })
        .finally(spinner.hide)
    }

    const getCompanies = (adSource = 'easy_post_to_approve') => {
      spinner.show()
      sbapibackoffice
        .get('/companies?adSource=' + adSource)
        .then(({ data }) => {
          state.campaignsType = adSource
          state.advertisement = data
        })
        .finally(spinner.hide)
    }

    const changeOrder = (orderBy) => {
      if (state.filters.order.field == orderBy) {
        state.filters.order.asc = !state.filters.order.asc
      } else {
        state.filters.order.field = orderBy
        state.filters.order.asc = true
      }
    }
    const getFilteredRows = () => {
      let rows = state.advertisement
        .filter((f) => {
          // if the filter is empty each row is allowed
          if (!state.search.length) {
            return true
          }

          let allFiltersArePresentInRow = true

          for (const filter of state.search) {
            //check company name
            let n = f.co_name
              ? f.co_name.toLowerCase().includes(filter.toLowerCase())
              : false
            //check first name user
            let u = f.firstNameUserBackoffice
              ? f.firstNameUserBackoffice
                .toLowerCase()
                .includes(filter.toLowerCase())
              : false
            //check last name user
            let l = f.lastNameUserBackoffice
              ? f.lastNameUserBackoffice
                .toLowerCase()
                .includes(filter.toLowerCase())
              : false
            //check coid
            let d = f.coid
              ? f.coid
                .toString()
                .toLowerCase()
                .includes(filter.toString().toLowerCase())
              : false

            allFiltersArePresentInRow =
              allFiltersArePresentInRow && (n || u || l || d)

            if (!allFiltersArePresentInRow) break
          }

          return allFiltersArePresentInRow
        })
        .filter(({ date }) =>
          !state.dateFrom ? true : date >= state.filters.dateFrom
        )
        .filter(({ date }) =>
          !state.dateTo ? true : date <= state.filters.dateTo
        )
        .filter((f) => {
          return state.filters.filterType.length > 0
            ? state.filters.filterType.indexOf(f.action) > -1
            : f
        })
        .sort(orderByColumnValues)
      return rows
    }
    const orderByColumnValues = (a, b) => {
      switch (state.filters.order.field) {
        case state.headers[0]: // companyName
          if (a.co_name.toLowerCase() < b.co_name.toLowerCase())
            return state.filters.order.asc ? -1 : 1
          if (a.co_name.toLowerCase() > b.co_name.toLowerCase())
            return state.filters.order.asc ? 1 : -1
          break
        case state.headers[1]: // coid
          if (a.coid < b.coid) return state.filters.order.asc ? -1 : 1
          if (a.coid > b.coid) return state.filters.order.asc ? 1 : -1
          break
        case state.headers[2]: // status
          if (a.easy_post_status < b.easy_post_status) return state.filters.order.asc ? -1 : 1
          if (a.easy_post_status > b.easy_post_status) return state.filters.order.asc ? 1 : -1
          break

        case state.headers[3]: // date
          if (a.campaign_date < b.campaign_date) return state.filters.order.asc ? -1 : 1
          if (a.campaign_date > b.campaign_date) return state.filters.order.asc ? 1 : -1
          break

        case state.headers[4]: // user
          if (a.approvedBy.toLowerCase() < b.approvedBy.toLowerCase())
            return state.filters.order.asc ? -1 : 1
          if (a.approvedBy.toLowerCase() > b.approvedBy.toLowerCase())
            return state.filters.order.asc ? 1 : -1
          break

        default:
          return 0
      }
      return 0
    }
    const filterSearch = ({ target }) => {
      state.search = target.value ? target.value.split(' ') : []
    }
    const isValidEmail = (email) => {
      const emailRegex = new RegExp(
        /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
        'gm'
      )
      return emailRegex.test(email)
    }
    const handleTagCreate = (option) => {
      if (!isValidEmail(option.value)) {
        // If returns `false` the tag will not be added
        toast.error(option.value + ' non è una email valida!')
        return false
      } else {
        return option
      }
    }
    const selectAll = () => {
      if (state.advertisementsSelected.length === getFilteredRows().length) {
        state.advertisementsSelected = []
      } else {
        state.advertisementsSelected = [
          ...new Set(getFilteredRows().map((adv) => adv.cid))
        ]
      }
    }
    const select = (coid, cid) => {
      let index = state.advertisementsSelected.indexOf(cid)
      if (index === -1) {
        state.advertisementsSelected.push(cid)

        // when click on a row checkbox if the row is hidden open the collapsible panel
        if (state.collapsiblePanelOpened !== coid) toggleExpand(coid, cid)
      }
      else state.advertisementsSelected.splice(index, 1)
    }
    const toggleExpand = (coid, cid) => {
      if (state.collapsiblePanelOpened === coid) {
        state.previewAdv = {}
        state.collapsiblePanelOpened = ''
      } else {
        spinner.show()

        sbapibackoffice
          .get('/campaignsbycid?cid=' + cid + '&lang=it')
          .then(({ data }) => {
            state.previewAdv = { ...data[0], ...state.advertisement.find(f => f.cid === cid) }
            api.post('/auth/get-company-data', { coid }).then(({ data }) => {
              state.previewAdv = { ...state.previewAdv, companyData: data }
            })
          })
          .finally(() => {
            state.collapsiblePanelOpened = coid
            spinner.hide()
          })
      }
    }
    watch(() => state.warning, () => {
      const ad = state.advertisement.find((f) => f.cid === state.advertisementsSelected[0])
      state.warningText.text = state.warning
      switch (state.warning) {
        case 'Logo azienda':
          switch (ad.locale) {
            case 'it':
              state.warningText.value = 'Logo della tua azienda: carica il logo della tua azienda'
              break
            case 'en':
              state.warningText.value = 'Your company logo: upload your company logo'
              break
            case 'es':
              state.warningText.value = 'El logo de tu empresa: sube el logo de tu empresa'
              break
            case 'fr':
              state.warningText.value = 'Ton logo d\'entreprise : télécharge le logo de ton entreprise'
              break
            case 'de':
              state.warningText.value = 'Dein Firmenlogo: Lade dein Firmenlogo hoch'
              break
            case 'pt':
              state.warningText.value = 'Logomarca de sua empresa: faça o upload da logomarca de sua empresa'
              break
          }
          break
        case 'Cover azienda':
          switch (ad.locale) {
            case 'it':
              state.warningText.value = 'Cover della tua azienda: carica un\'immagine cover della tua azienda'
              break
            case 'en':
              state.warningText.value = 'Cover of your company: upload a cover image of your company'
              break
            case 'es':
              state.warningText.value = 'La portada de tu empresa: sube una imagen de portada de tu empresa'
              break
            case 'fr':
              state.warningText.value = 'Couverture de ton entreprise : télécharge une image de couverture de ton entreprise.'
              break
            case 'de':
              state.warningText.value = 'Dein Firmencover: lade ein Titelbild deines Unternehmens hoch'
              break
            case 'pt':
              state.warningText.value = 'A capa de sua empresa: faça o upload de uma imagem da capa de sua empresa'
              break
          }
          break
        case 'Titolo annuncio':
          switch (ad.locale) {
            case 'it':
              state.warningText.value = 'Titolo annuncio: modifica il titolo del tuo annuncio. Assicurati che contenga esclusivamente la denominazione della posizione lavorativa. Ti consigliamo inoltre di utilizzare parole chiave che descrivano accuratamente la posizione, di evitare gergo aziendale e di non utilizzare abbreviazioni.'
              break
            case 'en':
              state.warningText.value = 'Ad title: edit the title of your ad. Make sure it contains only the name of the job position. We also recommend that you use keywords that accurately describe the position, avoid business jargon, and do not use abbreviations.'
              break
            case 'es':
              state.warningText.value = 'Título del anuncio: cambia el título de tu anuncio. Asegúrate de que sólo contiene el nombre del puesto de trabajo. También te recomendamos que utilices palabras clave que describan con precisión el puesto, que evites la jerga corporativa y que no utilices abreviaturas.'
              break
            case 'fr':
              state.warningText.value = 'Titre de l\'annonce : modifie le titre de ton annonce. Assure-toi qu\'il ne contient que le nom du poste. Nous te recommandons également d\'utiliser des mots clés qui décrivent précisément le poste, d\'éviter le jargon d\'entreprise et de ne pas utiliser d\'abréviations.'
              break
            case 'de':
              state.warningText.value = 'Anzeigentitel: Ändere den Titel deiner Anzeige. Achte darauf, dass er nur den Namen der Stelle enthält. Wir empfehlen außerdem, dass du Schlüsselwörter verwendest, die die Stelle genau beschreiben, Unternehmensjargon vermeidest und keine Abkürzungen verwendest.'
              break
            case 'pt':
              state.warningText.value = 'Título do anúncio: mude o título do seu anúncio. Certifique-se de que ele contenha apenas o nome do cargo. Nós também recomendamos que você use palavras-chave que descrevam a posição com precisão, evite jargões corporativos e não use abreviações.'
              break
          }
          break
        case 'Video-colloquio':
          switch (ad.locale) {
            case 'it':
              state.warningText.value = 'Video-colloquio: controlla le domande che vuoi proporre ai candidati nel video-colloquio. Assicurati che non contengano richieste riguardanti genere, età, religione, o prescrizioni personali attinenti alle libertà personali'
              break
            case 'en':
              state.warningText.value = 'Pending interview: check the questions you want to propose to candidates in the Pending Interview. Make sure they do not contain requests regarding gender, age, religion, or personal requirements pertaining to personal liberties.'
              break
            case 'es':
              state.warningText.value = 'Entrevista pendiente: marca las preguntas que quieres hacer a los candidatos en la Entrevista pendiente. Asegúrate de que no contengan ninguna solicitud relativa al género, la edad, la religión o los requisitos personales relativos a las libertades personales.'
              break
            case 'fr':
              state.warningText.value = 'Entretien en attente: coche les questions que tu veux poser aux candidats dans l\'entretien en attente. Assure-toi qu\'ils ne contiennent aucune demande concernant le sexe, l\'âge, la religion ou des exigences personnelles concernant les libertés individuelles.'
              break
            case 'de':
              state.warningText.value = 'Ausstehendes Interview: Überprüfe die Fragen, die du den Bewerbern stellen willst, im Feld Ausstehendes Interview. Vergewissere dich, dass sie keine Anfragen zu Geschlecht, Alter, Religion oder persönlichen Anforderungen an die persönlichen Freiheiten enthalten.'
              break
            case 'pt':
              state.warningText.value = 'Entrevista Pendente: verifique as perguntas que você quer fazer aos candidatos na Entrevista Pendente. Certifique-se de que eles não contenham nenhuma solicitação relativa ao sexo, idade, religião ou exigências pessoais relativas às liberdades pessoais.'
              break
          }
          break
        case 'Contenuto dell\'annuncio':
          switch (ad.locale) {
            case 'it':
              state.warningText.value = 'Contenuto dell\'annuncio: aggiungere informazioni'
              break
            case 'en':
              state.warningText.value = 'Ad content: add information'
              break
            case 'es':
              state.warningText.value = 'Contenido del anuncio: añadir información'
              break
            case 'fr':
              state.warningText.value = 'Contenu de l\'annonce : ajouter des informations'
              break
            case 'de':
              state.warningText.value = 'Anzeigentext: Informationen hinzufügen'
              break
            case 'pt':
              state.warningText.value = 'Conteúdo do anúncio: adicionar informações'
              break
          }
          break
      }
    })
    const addWarning = () => {
      state.previewListWarning.push(JSON.parse(JSON.stringify(state.warningText)))
    }
    const setFirstItem = () => {
      state.warning = state.warningOpt[0]
    }
    const removeWarning = (w) => {
      let index = state.previewListWarning.indexOf(w)
      state.warningOpt.push(w.text)
      state.previewListWarning.splice(index, 1)
    }
    const getWarningsOpt = computed(() => {
      state.warningOpt = state.warningOpt.filter(option => { return !state.previewListWarning.find(o => { return o.text === option }) })
      setFirstItem()
      return state.warningOpt.sort()
    })

    const easyPostStatusOnError = (error, cid) => {
      switch (error.response.data.messageCode) {
        case 'ESP-001':
          toast.error('easypost status not allowed or does not exist')
          break
        case 'ESP-002':
          toast.error(`record of campaign for input cid ${cid} not exist`)
          break
        case 'ESP-003':
          toast.error('source of campaign is not easy_post')
          break
        case 'ESP-004':
          if (state.advertisement.find(f => f.cid === cid).easy_post_status === 3) {
            toast.error('L\'azienda sta modificando l\'annuncio non approvato e lo invierà nuovamente in revisione. Fino ad allora, non potrai approvarlo')
          } else {
            toast.error('Lo stato della campagna non può essere modificato')
          }
          break
        case 'ESP-005':
          toast.error('Il recruiter che ha creato l\'annuncio non è stato trovato')
          break
        default:
          toast.error('Qualcosa è andato storto')
          break
      }
    }

    const openApproveDialog = () => {
      approveDialog.value.open()
    }

    const openDeleteDialog = () => {
      deleteDialog.value.open()
    }

    const approveAdvertisment = async () => {
      await previewAdvRef.value[0].updateCampaign()
        .then((err) => {
          if (err?.err) {
            console.log(err)
            toast.error(err.message)
            spinner.hide()

            return
          }

          sbapibackoffice
            .put('/easypost/campaign/status', {
              cid: state.advertisementsSelected[0],
              easyPostStatus: 2
            })
            .then(({ data }) => {
              if (data.response === 'OK') {
                state.advertisement.find(ad => ad.cid === state.advertisementsSelected[0]).easy_post_status = 2
                state.showWarning = false
              }
            })
            .catch((error) => easyPostStatusOnError(error, state.advertisementsSelected[0]))
            .finally(() => {
              approveDialog.value.close()
              spinner.hide()

            })
        })
    }

    const removeAdvertisment = () => {
      spinner.show()
      sbapibackoffice
        .put('/easypost/campaign/status', {
          cid: state.advertisementsSelected[0],
          easyPostStatus: 4
        })
        .then(({ data }) => {
          if (data.response === 'OK') {
            state.advertisement.find(ad => ad.cid === state.advertisementsSelected[0]).easy_post_status = 4
            state.showWarning = false
          }
        })
        .catch((error) => easyPostStatusOnError(error, state.advertisementsSelected[0]))
        .finally(() => {
          spinner.hide()
          deleteDialog.value.close()
        })
    }

    const rejectAdvertisment = () => {
      spinner.show()
      sbapibackoffice
        .put('/easypost/campaign/status', {
          cid: state.advertisementsSelected[0],
          easyPostStatus: 3,
          emailMessage: state.previewListWarning.map(m => '• ' + m.text + ': ' + m.value).join('\n ')
        })
        .then(({ data }) => {
          if (data.response === 'OK') {
            state.advertisement.find(ad => ad.cid === state.advertisementsSelected[0]).easy_post_status = 3
            state.showWarning = false
          }
        })
        .catch((error) => easyPostStatusOnError(error, state.advertisementsSelected[0]))
        .finally(spinner.hide)
    }

    const resendEmailEnabled = () => {
      if (!state.advertisementsSelected.length || state.advertisementsSelected.length > 1) return 

      const ad = state.advertisement.find(ad => ad.cid === state.advertisementsSelected[0])

      if (!ad.mail_verify_account) return

      return ad.mail_verify_account[0].email_state != 0 || ad.mail_verify_account[0].email_state != 1
    }

    const openResendEmailModal = () => {
      const ad = state.advertisement.find(ad => ad.cid === state.advertisementsSelected[0])
      state.newEmail = ad.mail_verify_account[0].email_to
      state.showResendEmailModal = true
    }

    const closeResendEmailModal = () => {
      state.showResendEmailModal = false
    }

    const resendEmail = () => {
      spinner.show()

      const ad = state.advertisement.find(ad => ad.cid === state.advertisementsSelected[0])
      
      const mva = ad.mail_verify_account[0]

      sbapibackoffice
        .post('/easypost/campaign/sendconfirmemail', {
          id: mva.id,
          uid: mva.uid,
          email_to: state.newEmail
        })
        .then(({ data }) => {
          mva.resend_date = data.resend_date
          mva.email_state = data.email_state
          mva.resent_date = new Date()

          state.showResendEmailModal = false
          toast.success('Email modificata correttamente')
        })
        .catch((error) => {
          switch (error.response.data.messageCode) {
            case 'ESP-001':
              toast.error('Email obbligatoria')
              break
            case 'ESP-002':
              toast.error('Uid obbligatorio')
              break
            case 'ESP-003':
              toast.error('Email non trovata')
              break
            case 'ESP-004':
              toast.error('Email gia inviata')
              break
            case 'ESP-005':
              toast.error('Email diversa da quella registrata')
              break
            default:
              toast.error('Email usata da un altro utente')
              break
          }
        })
        .finally(spinner.hide)
    }

    return {
      ...toRefs(state),
      getWarningsOpt,
      filterSearch,
      isValidEmail,
      handleTagCreate,
      changeOrder,
      getFilteredRows,
      selectAll,
      select,
      toggleExpand,
      addWarning,
      removeWarning,
      deleteDialog,
      openDeleteDialog,
      removeAdvertisment,
      rejectAdvertisment,
      approveDialog,
      openApproveDialog,
      approveAdvertisment,
      previewAdvRef,
      getCompanies,
      permissions,
      resendEmailEnabled,
      resendEmail,
      openResendEmailModal,
      closeResendEmailModal
    }
  }
}
</script>
<style lang="scss">
.jobAdvertisement {
  padding: 40px;

  .avatar-title {
    margin-top: 15px;
    align-items: center;
    display: flex;
    align-content: center;
    justify-content: center;
    background: rgb(19 64 90);
    position: relative;
    width: 90px;
    height: 90px;
    border-radius: 50%;

    .newspaper {
      width: 50px;
      height: 50px;
      margin-left: 25px;
      margin-top: -15px;
    }

    .announcement {
      width: 60px;
      height: 60px;

      position: absolute;
      left: -11px;
      top: 30px;
    }
  }

  .warning-text {
    margin-top: 25px;
    margin-bottom: 15px;
    font-size: 14px;
  }

  .warning-wrapper {
    width: 100%;
    align-items: center;
    display: flex;

    .vs__dropdown-menu {
      width: 200px !important;
    }

    .roundedPlus {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 6px;
      border-radius: 50%;
      min-height: 35px;
      max-height: 35px;
      font-size: 30px;
      max-width: 35px;
      min-width: 35px;
      margin-left: 15px;
      border: 0;
      outline: 0;
      background: #13405A;

      svg {
        path {
          fill: #c6e0ff;
        }
      }
    }

    .input-container {
      border-radius: 2px;
      padding: 0 5px;
      width: 100%;
      margin-top: 5px;
      display: flex;
      // border: 1px solid #cad0d792;
      background: rgba(215, 234, 255, 0.5);
      align-items: center;
      height: 50px;

      .vs__actions {
        background: #c6e0ff;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        path {
          stroke: black !important;
          fill: black !important;
        }
      }

      .v-select {
        height: 50px;
        display: flex;
        align-items: center;
        border: 0;
        min-width: 140px;
        max-width: 140px;
        width: 140px;
        background: transparent;
        color: black;

        .vs__dropdown-toggle {
          width: 110px;
          height: 30px;
          min-width: 110px;
          max-width: 110px;
          background: #c6e0ff;
          border-radius: 5px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        .vs__selected-options {
          width: 110px;
          min-width: 110px;
          max-width: 110px;
        }

        .vs__dropdown-menu {
          top: 80% !important;
          border-top-left-radius: 0;
          background: #c6e0ff;

          li {
            color: black;
            background: transparent;

            &:hover {
              color: white;
              background: #487dbd;
            }
          }
        }

        .vs__selected {
          //background: #004176;
          color: black !important;
          width: 110px;
          min-width: 110px;
          max-width: 110px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
      }

      textarea {
        margin-left: 15px;
        font-size: 14px;
        font-family: "Lato", sans-serif;
        resize: none;
        background: transparent;
        width: 100%;
        border: 0;
        outline: 0;

        &:focus,
        &:focus-visible {
          outline: 0;
        }
      }
    }
  }

  .preview-list {
    max-height: 150px;
    overflow-y: auto;
    margin-top: 15px;
    background: rgba(215, 234, 255, 0.5);
    padding: 10px 20px;
    border-radius: 2px;
    width: 100%;

    i {
      font-size: 14px;
      padding-bottom: 5px;
    }

    ul {
      margin-top: 8px;
      list-style-position: inside;

      li {
        margin-left: 10px;
        font-size: 13px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .warning-action {
    margin-top: 15px;
  }

  .table {
    height: fit-content;
    max-height: 75vh;
    overflow-y: auto;
    min-width: 100%;
    overflow-x: auto;
  }

  .collapsible-content {
    background: #004176;
    padding: 10px 5px;
  }

  .filters {
    margin-bottom: 40px;

    .filters-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      .notification-select,
      .search {
        min-height: 42px;
        color: rgba(255, 255, 255, 0.65);
        border: thin solid rgba(244, 244, 244, 0.55);
        border-radius: 5px;
        box-sizing: border-box;
        display: flex;
        flex: 1;
        align-items: center;
        padding: 3px 15px;

        label {
          font-size: 13px;
          padding-bottom: 1px;
          color: rgba(255, 255, 255, 0.75);
        }
      }

      .vs__selected {
        background: var(--info);
        font-size: 13px;
        color: white;
      }

      button.vs__deselect {
        svg {
          transform: scale(0.6);

          path {
            fill: white !important;
          }
        }
      }

      .multiselect-dropdown.is-hidden {
        //display: block!important;
      }

      .multiselect-dropdown {
        background: #004278;
        color: white;
        padding: 0 !important;

        li {
          background: transparent !important;
          color: white;
        }
      }

      .multiselect {
        display: flex;
        border: 0;
        flex: 1;
        background: transparent;
        outline: 0 !important;
        box-shadow: none !important;
      }

      input {
        color: white;
        background: none;
        border: none;
        padding-left: 10px;
        flex: 1;
        outline: 0 !important;
        box-shadow: none !important;

        &:focus,
        &:focus-visible {
          outline: 0 !important;
          box-shadow: none !important;
          border: 0 !important;
        }

        &::placeholder {
          font-size: 13px;
          color: rgba(255, 255, 255, 0.55);
        }
      }
    }
  }

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;

    &.dot-color1 {
      background-color: #ffda07;
    }

    &.dot-color2 {
      background-color: #09ff00;
    }

    &.dot-color3 {
      background-color: #ff7700;
    }

    &.dot-color4 {
      background-color: #ff0000;
    }
  }
}
</style>
<style lang="scss" scoped>
@use "../assets/scss/referrals";
@use "../assets/scss/table";
@use "../assets/scss/dialog";

.buttons {
  display: flex;

  .btn-badge {
    margin-left: auto;
    position: relative;

    span.counter-badge {
      position: absolute;
      top: -6px;
      right: -6px;
      background-color: red;
      border-radius: 50%;
      color: white;
      height: 20px;
      min-width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.dialog-content {
  width: 800px;

  b {
    color: #f47676;
  }
}

.table table thead th.orderable div {
  text-align: left;
  justify-content: flex-start;
}

.chevron {
  width: 50px;

  svg {
    transform: rotate(0deg);
    transition: all 250ms ease-in-out;
  }

  &.--open {
    svg {
      transform: rotate(90deg);
      transition: all 150ms ease-in-out;
    }
  }
}

/* .grow-enter {
	opacity: 0;
}

.grow-enter-active {
	animation: grow-in 300ms ease-in forwards;
	transition: opacity 0.5s;
}

.grow-leave {
	// initial state set in keyframe
}

.grow-leave-active {
	animation: grow-out 300ms ease-out forwards;
	opacity: 0;
	transition: opacity 1s;
} */
@keyframes grow-in {
  from {
    opacity: 0.75;
    transform: translateY(-5px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes grow-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(5px);
  }
}

.action-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.custom-inputs {
  input {
    padding: 7px 5px;
    background-color: #eee;
    border-color: #eee;
    border-radius: 3px;
    outline: none;
    border: none;
  }
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
